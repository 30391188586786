<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.redirect') }}
        </h3>
      </b-col>
    </b-row>
    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col lg="7" sm="12" class="mb-3">
          <b-input-group class="mb-3" :prepend="$t('inputs.stage')">
            <b-form-select
              required
              v-model="model.fromStageId"
              :options="stages"
            />
          </b-input-group>
          <b-input-group :prepend="$t('inputs.url')" class="mb-3">
            <b-form-input required v-model="model.toUrl" type="url" />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="7" sm="12" class="mb-3 text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Toast from '../../../../assets/js/toast';
import GeneralCommon from '../../../../services/GeneralCommon';
import GeneralRedirect from '../../../../services/GeneralRedirect';

export default {
  name: 'RedirectForm',

  data() {
    const data = {
      loaded: false,
      options: [],

      model: {
        fromStageId: '',
        toUrl: Vue.prototype.VUE_APP_FRONT_URL,
      },
    };
    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function () {
      this.onLoad();
    },
  },

  methods: {
    async onLoad() {
      this.loading = true;
      this.options = await GeneralCommon.stages();

      this.loading = false;
      this.loaded = true;
    },

    async onSubmit() {
      try {
        this.loading = true;
        await GeneralRedirect.save(this.model);
        Toast.success(this, 'messages.saveSuccess');
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(error);
        });
        Toast.error(this, error);
        this.loading = false;
      } finally {
        this.loading = false;
        this.model = {
          fromStageId: '',
          toUrl: '',
        };
      }
    },
  },
  computed: {
    stages() {
      return this.options.map((stage) => ({
        value: stage.id,
        text: stage.name,
      }));
    },
  },
};
</script>

<style>
</style>

import Vue from 'vue';

export default {
  genders: async () => Vue.http.get('general/common/genders')
    .then((res) => res.json()),

  stages: async () => Vue.http.get('general/common/stages')
    .then((res) => res.json()),

  states: async () => Vue.http.get('general/common/location/states')
    .then((res) => res.json()),
};
